import React from "react";
import PropTypes from "prop-types";
import styles from "./locations.module.scss";

const Locations = props => {
  const { heading, locations } = props;
  return (
    <section className={styles.locations}>
      <h3 className={styles.heading}>{heading}</h3>
      {locations.map(location => (
        <div key={location.name} className={styles.locationBlock}>
          <img
            className={styles.locationMap}
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.coordinates}&zoom=14&size=400x400&key=AIzaSyAexA5_FcIQ17KXUMC2LrQ1O--z4kbNRp8`}
            alt=""
          />
          <div className={styles.locationDetails}>
            <h6 className={styles.locationName}>{location.name}</h6>
            <p className={styles.locationAddress}>{location.address}</p>
            <p className={styles.locationOperation}>
              {location.operationHours}
            </p>
          </div>
        </div>
      ))}
    </section>
  );
};

Locations.propTypes = {
  heading: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Locations;
