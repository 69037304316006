import React from "react";
import PropTypes from "prop-types";
import styles from "./contact-details.module.scss";

const ContactDetails = props => {
  const { email, phone } = props;
  return (
    <section className={styles.contactDetails}>
      <h3 className={styles.heading}>Contact</h3>
      <address className={styles.details}>
        <a href={`mailto:${email}`} className={styles.email}>
          {email}
        </a>
        <a href={`tel:${phone}`} className={styles.phone}>
          {phone}
        </a>
      </address>
    </section>
  );
};

ContactDetails.propTypes = {
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
};

export default ContactDetails;
