import React from "react";
import styles from "./contact-form.module.scss";

const ContactForm = () => {
  const [nameValue, setNameValue] = React.useState("");
  const [emailValue, setEmailValue] = React.useState("");
  const [numberValue, setNumberValue] = React.useState("");
  const [messageValue, setMessageValue] = React.useState("");

  const handleChange = setValue => e => setValue(e.target.value);

  return (
    <section className={styles.contactForm}>
      <h3 className={styles.heading}>
        If you have a general enquiry, please use the following form.
      </h3>
      <form className={styles.formContainer} action="" id="contact-form">
        <div>
          <label htmlFor="name">
            Name:
            <input
              type="text"
              id="name"
              value={nameValue}
              onChange={handleChange(setNameValue)}
            />
          </label>
        </div>
        <div>
          <label htmlFor="email">
            Email:
            <input
              type="email"
              id="email"
              value={emailValue}
              onChange={handleChange(setEmailValue)}
            />
          </label>
        </div>
        <div>
          <label htmlFor="number">
            Phone:
            <input
              type="tel"
              id="number"
              value={numberValue}
              onChange={handleChange(setNumberValue)}
            />
          </label>
        </div>
        <div>
          <label htmlFor="message">
            Message:
            <textarea
              id="message"
              value={messageValue}
              onChange={handleChange(setMessageValue)}
            />
          </label>
        </div>
        <button
          className={styles.button}
          form="contact-form"
          type="submit"
          value="submit"
        >
          Submit
        </button>
      </form>
    </section>
  );
};

export default ContactForm;
