import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Button from "../components/button";
import Locations from "../components/locations";
import ContactDetails from "../components/contact-details";
import ContactForm from "../components/contact-form";

const Contact = ({ data }) => {
  const {
    description,
    banner,
    locationsContainer,
    contactDetails
  } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Seo
        title={banner.title}
        description={description}
        imagePath={banner.bannerImage.publicURL}
      />
      <Hero
        fluid={banner.bannerImage.childImageSharp.fluid}
        title={banner.title}
        blurb={banner.blurb}
      />
      <Button url={banner.button.link} name={banner.button.name} standalone />
      <Locations
        heading={locationsContainer.heading}
        locations={locationsContainer.locations}
      />
      <ContactForm />
      <ContactDetails
        email={contactDetails.email}
        phone={contactDetails.phone}
      />
    </Layout>
  );
};

Contact.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default Contact;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      frontmatter {
        description
        banner {
          title
          bannerImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blurb
          button {
            name
            link
          }
        }
        locationsContainer {
          heading
          locations {
            coordinates
            name
            address
            operationHours
          }
        }
        contactDetails {
          email
          phone
          facebook
          twitter
          linkedIn
        }
      }
    }
  }
`;
